import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { FiEye, FiEyeOff } from "react-icons/fi"; // Icons for showing/hiding password
import Spinner from "../components/Spinner";

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const loggedIn = await login(email, password);
      if (loggedIn) navigate("/");
      else setError("Login failed. Please try again.");
    } catch (err) {
      setError("Login failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="bg-blue-500 h-screen flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        {/* Title DeepQ */}
        <h1 className="text-center text-3xl font-semibold mb-6">
          <span className="text-blue-800">Deep</span>
          <span className="text-violet-700">Q</span>
        </h1>

        <form onSubmit={handleLogin}>
          {/* Email Label and Input */}
          <label
            className="block text-gray-500 font-medium mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            id="email"
            type="email"
            placeholder="example@example.com"
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          {/* Password Label and Input with toggle */}
          <label
            className="block text-gray-500 font-normal mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <div className="relative">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              className="w-full p-3 mb-4 border border-gray-300 rounded-lg"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {/* Eye icon to toggle password visibility */}
            <div
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-3 bottom-3 flex items-center cursor-pointer text-gray-500"
            >
              {showPassword ? <FiEyeOff size={20} /> : <FiEye size={20} />}
            </div>
          </div>

          {/* Forgot password link */}
          <p className="text-center text-violet-700 text-sm mb-4 cursor-pointer hover:underline">
            Forgot the password?
          </p>

          {/* Login Button */}
          <button
            type="submit"
            className="w-full bg-violet-700 text-white p-3 rounded-lg hover:bg-violet-800 transition"
            disabled={loading}
          >
            {loading ? <Spinner /> : "Login"}
          </button>
        </form>

        {/* Error Message */}
        {error && <p className="text-red-500 mt-4">{error}</p>}

        {/* Sign Up Section */}
        <p className="mt-4 text-gray-600 text-center">
          Not registered yet?{" "}
          <span
            onClick={() => navigate("/register")}
            className="text-violet-700 hover:underline cursor-pointer"
          >
            Sign up
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
