import React, { useState, useEffect } from "react";
import {
  getAdminPrompt,
  updateAdminPrompt,
  addComponentType,
  getComponents,
  updateComponentType,
  deleteComponentType,
} from "../services/ApiService";
import Modal from "./Modal";
import { ComponentType } from "models/ComponentType";

// Tag component for displaying key-value pairs with a delete option
const Tag = ({
  acronym,
  onDelete,
}: {
  acronym: { key: string; value: string };
  onDelete: () => void;
}) => (
  <div className="inline-flex items-center bg-gray-200 text-gray-800 px-2 py-1 rounded-full mr-2 mb-2">
    <span>
      {acronym.key}: {acronym.value}
    </span>
    <button onClick={onDelete} className="ml-2 text-red-500 hover:text-red-700">
      &times;
    </button>
  </div>
);

interface AdminPromptProps {
  onClose: () => void;
  onComponentUpdate: () => void;
}

const AdminPrompt: React.FC<AdminPromptProps> = ({
  onClose,
  onComponentUpdate,
}) => {
  const [acronyms, setAcronyms] = useState<{ key: string; value: string }[]>(
    []
  );
  const [acronymName, setAcronymName] = useState<string>("");
  const [acronymValue, setAcronymValue] = useState<string>("");
  const [companyDescription, setCompanyDescriptionState] = useState("");
  const [departmentOutline, setDepartmentOutlineState] = useState("");
  const [componentTypes, setComponentTypes] = useState<ComponentType[]>([]);
  const [selectedComponent, setSelectedComponent] =
    useState<ComponentType | null>(null);
  const [isComponentModalOpen, setIsComponentModalOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchAdminPrompt = async () => {
      try {
        const data = await getAdminPrompt();

        const acronymsParsed = data
          ? data.acronyms.split(";").map((acronym) => {
              const [key, value] = acronym.split(":");
              return { key, value };
            })
          : [];

        setCompanyDescriptionState(data.company_description || "");
        setDepartmentOutlineState(data.department_outline || "");
        setAcronyms(acronymsParsed);
        const components = await getComponents();
        setComponentTypes(components || []);
      } catch (error) {
        console.error("Failed to fetch admin prompt:", error);
      }
    };

    fetchAdminPrompt();
  }, []);

  // Auto-save function
  const handleSave = async () => {
    try {
      const acronymsParsed = acronyms
        .map((acronym) => `${acronym.key}:${acronym.value}`)
        .join(";");
      const data = {
        company_description: companyDescription,
        department_outline: departmentOutline,
        acronyms: acronymsParsed,
      };
      console.log(acronyms);

      await updateAdminPrompt(data);
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 2000);
    } catch (error) {
      console.error("Failed to save admin prompt:", error);
    }
  };
  const handleSaveWithAcronyms = async (
    updatedAcronyms: { key: string; value: string }[]
  ) => {
    try {
      const acronymsParsed = updatedAcronyms
        .map((acronym) => `${acronym.key}:${acronym.value}`)
        .join(";");
      const data = {
        company_description: companyDescription,
        department_outline: departmentOutline,
        acronyms: acronymsParsed,
      };

      await updateAdminPrompt(data);
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 2000);
    } catch (error) {
      console.error("Failed to save admin prompt:", error);
    }
  };

  // Debounce function
  const debounceSave = () => {
    if (saveTimeout) clearTimeout(saveTimeout);
    const timeout = setTimeout(handleSave, 2000);
    setSaveTimeout(timeout);
  };

  // Wrapped state setters to trigger debounced save
  const setCompanyDescription = (value: string) => {
    setCompanyDescriptionState(value);
    //debounceSave();
  };

  const setDepartmentOutline = (value: string) => {
    setDepartmentOutlineState(value);
    //debounceSave();
  };

  const handleAddAcronym = () => {
    if (acronymName && acronymValue) {
      // Use functional update to ensure the latest state
      setAcronyms((prevAcronyms) => {
        const updatedAcronyms = [
          ...prevAcronyms,
          { key: acronymName, value: acronymValue },
        ];
        // Perform handleSave with the updated acronyms list
        handleSaveWithAcronyms(updatedAcronyms);
        return updatedAcronyms;
      });

      // Clear the input fields
      setAcronymName("");
      setAcronymValue("");
    }
  };

  const handleDeleteAcronym = (index: number) => {
    setAcronyms(acronyms.filter((_, i) => i !== index));
    debounceSave();
  };

  const openComponentModal = (component?: ComponentType) => {
    setSelectedComponent(component || { id: "", name: "", prompt: "" });
    setIsComponentModalOpen(true);
  };

  const closeComponentModal = () => {
    setIsComponentModalOpen(false);
    setSelectedComponent(null);
  };

  const handleComponentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!selectedComponent) return;
    const { name, value } = e.target;
    setSelectedComponent({ ...selectedComponent, [name]: value });
  };

  const handleSaveComponent = async () => {
    if (selectedComponent) {
      try {
        if (selectedComponent.id) {
          // Update existing component
          await updateComponentType(selectedComponent);
        } else {
          // Add new component and get the response with the ID
          const newComponent = await addComponentType(selectedComponent);

          // Update the state with the newly created component (including the ID)
          setComponentTypes((prev) => [...prev, newComponent]);
        }

        // Update the state if the component was edited (existing component)
        setComponentTypes((prev) =>
          selectedComponent.id
            ? prev.map((comp) =>
                comp.id === selectedComponent.id ? selectedComponent : comp
              )
            : prev
        );

        closeComponentModal();
        onComponentUpdate();
      } catch (error) {
        console.error("Failed to save component:", error);
      }
    }
  };

  const handleDeleteComponent = async (id: string) => {
    try {
      await deleteComponentType(id);
      setComponentTypes((prev) => prev.filter((comp) => comp.id !== id));
      onComponentUpdate();
    } catch (error) {
      console.error("Failed to delete component:", error);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg max-w-3xl mx-auto">
      {showSuccessMessage && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg z-50">
          Changes saved successfully!
        </div>
      )}

      <h2 className="text-xl font-bold">
        You are the admin for your department
      </h2>
      <p className="text-gray-500">
        This setup will help the model provide better outputs.
      </p>

      <div className="mt-4">
        <h3 className="font-bold">Describe your organization</h3>
        <textarea
          className="w-full border rounded-lg p-2 mt-2"
          rows={4}
          placeholder="Describe here..."
          value={companyDescription}
          onChange={(e) => setCompanyDescription(e.target.value)}
          onBlur={handleSave}
        ></textarea>
      </div>

      <div className="mt-4">
        <h3 className="font-bold">Describe your department</h3>
        <textarea
          className="w-full border rounded-lg p-2 mt-2"
          rows={4}
          placeholder="Describe here..."
          value={departmentOutline}
          onChange={(e) => setDepartmentOutline(e.target.value)}
          onBlur={handleSave}
        ></textarea>
      </div>

      <div className="mt-4">
        <h3 className="font-bold">Introduce important acronyms</h3>
        <div className="flex space-x-2 mt-2">
          <input
            type="text"
            className="border rounded-lg p-2 flex-1"
            placeholder="Acronym name"
            value={acronymName}
            onChange={(e) => setAcronymName(e.target.value)}
          />
          <input
            type="text"
            className="border rounded-lg p-2 flex-1"
            placeholder="Acronym"
            value={acronymValue}
            onChange={(e) => setAcronymValue(e.target.value)}
          />
          <button
            type="button"
            onClick={handleAddAcronym}
            className="bg-violet-700 text-white px-4 py-2 rounded-lg hover:bg-violet-800"
          >
            +
          </button>
        </div>

        <div className="mt-4 flex flex-wrap">
          {acronyms.map((acronym, index) => (
            <Tag
              key={index}
              acronym={acronym}
              onDelete={() => handleDeleteAcronym(index)}
            />
          ))}
        </div>
      </div>

      <div className="mt-6">
        <h3 className="font-bold">Component Types</h3>
        <button
          onClick={() => openComponentModal()}
          className="bg-violet-700 text-white px-4 py-2 rounded-lg hover:bg-violet-800 mb-4"
        >
          + Add Component Type
        </button>

        <div className="space-y-2">
          {componentTypes.map((component) => (
            <div key={component.id} className="flex bg-gray-200 p-2 rounded">
              <span className="flex-1">{component.name}</span>
              <button
                onClick={() => openComponentModal(component)}
                className="text-violet-700 hover:bg-violet-700 hover:text-white pr-4 pl-4 rounded-full"
              >
                Edit
              </button>
              <button
                onClick={() => handleDeleteComponent(component.id!)}
                className="text-red-600 hover:bg-red-600 hover:text-white pr-4 pl-4 rounded-full"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
      <button
        type="button"
        className="mt-6 bg-violet-700 text-white px-4 py-2 rounded-lg hover:bg-violet-800"
        onClick={handleSave}
      >
        Save
      </button>
      {isComponentModalOpen && (
        <Modal isVisible={isComponentModalOpen} onClose={closeComponentModal}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSaveComponent();
            }}
          >
            <input
              name="name"
              className="w-full border rounded-lg p-2 mt-2"
              placeholder="Component Name"
              value={selectedComponent?.name || ""}
              onChange={handleComponentChange}
            />
            <textarea
              name="prompt"
              className="w-full border rounded-lg p-2 mt-2"
              rows={4}
              placeholder="Component Description"
              value={selectedComponent?.prompt || ""}
              onChange={handleComponentChange}
            ></textarea>
            <button
              type="submit"
              className="bg-violet-700 text-white px-4 py-2 rounded-lg"
            >
              Save Component
            </button>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default AdminPrompt;
