import React, { useState, useEffect } from "react";
import { getComponents } from "../services/ApiService"; // Import the API function

interface EightDSourceProps {
  selectedDocumentTypes: string[];
  onSelectionChange: (selected: string[]) => void;
  refreshTrigger: number; // Trigger prop to refetch data
}

const EightDSource: React.FC<EightDSourceProps> = ({
  selectedDocumentTypes,
  onSelectionChange,
  refreshTrigger,
}) => {
  const [componentOptions, setComponentOptions] = useState<
    { id: string; label: string }[]
  >([]);

  // Fetch component types on mount and whenever refreshTrigger changes
  useEffect(() => {
    const fetchComponentTypes = async () => {
      try {
        const data = await getComponents();
        const formattedOptions = data.map((component: any) => ({
          id: component.id,
          label: component.name,
        }));
        setComponentOptions(formattedOptions);
      } catch (error) {
        console.error("Failed to fetch component types:", error);
      }
    };

    fetchComponentTypes();
  }, [refreshTrigger]); // Dependency on refreshTrigger

  const handleCheckboxChange = (id: string) => {
    const updatedSelection = selectedDocumentTypes.includes(id)
      ? selectedDocumentTypes.filter((item) => item !== id)
      : [...selectedDocumentTypes, id];
    onSelectionChange(updatedSelection);
  };

  return (
    <div className="mt-8 bg-white p-4 rounded-lg shadow-md">
      <h3 className="text-lg font-bold mb-4">8D Source</h3>
      <ul className="space-y-2">
        {componentOptions.map((option) => (
          <li key={option.id} className="flex items-center">
            <input
              id={option.id}
              type="checkbox"
              checked={selectedDocumentTypes.includes(option.label)}
              onChange={() => handleCheckboxChange(option.label)}
              className="form-checkbox h-5 w-5 text-violet-700 rounded transition duration-150 ease-in-out"
            />
            <label
              htmlFor={option.id}
              className="ml-3 text-gray-700 font-medium"
            >
              {option.label}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EightDSource;
