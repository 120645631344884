import React, { useState } from "react";
import Modal from "./Modal";
import { SendImprovementReport } from "services/ApiService";

interface ReportModalProps {
  reportType: "bug" | "feature";
  isVisible: boolean;
  onClose: () => void;
}

const ReportModal: React.FC<ReportModalProps> = ({
  reportType,
  isVisible,
  onClose,
}) => {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<"idle" | "sending" | "sent" | "error">(
    "idle"
  );

  const handleSendReport = async () => {
    setStatus("sending");
    try {
      // Placeholder for actual API call
      await SendImprovementReport(reportType, message);

      setStatus("sent");
      setTimeout(() => {
        setMessage("");
        setStatus("idle");
        onClose(); // Close the modal after a delay
      }, 1000);
    } catch (error) {
      setStatus("error");
    }
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose} size="large">
      <h2 className="text-xl font-semibold mb-4">
        {reportType === "bug" ? "Report a Bug / Issue" : "Feature Request"}
      </h2>
      <textarea
        className="w-full h-60 p-2 border rounded-lg"
        placeholder={
          reportType === "bug"
            ? "Describe the bug or issue..."
            : "Describe the feature you'd like to request..."
        }
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        title="Enter your message"
      />
      <button
        onClick={handleSendReport}
        disabled={status === "sending"}
        className="mt-4 w-full bg-violet-700 text-white p-2 rounded-lg hover:bg-violet-900 transition"
      >
        {status === "sending" ? "Sending..." : "Send"}
      </button>
      {status === "sent" && (
        <p className="text-green-500 mt-2">Report sent successfully!</p>
      )}
      {status === "error" && (
        <p className="text-red-500 mt-2">
          Failed to send report. Try again later.
        </p>
      )}
    </Modal>
  );
};

export default ReportModal;
