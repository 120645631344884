import axios from "axios";
import User from "models/User";
import Conversation from "models/Conversation";
import Message from "models/Message";
import { ComponentType } from "models/ComponentType";

// Create the API client
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT, // Your API base URL
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // Always send cookies for authentication
});

// Sign up a new user
export const signup = async (userData: User): Promise<User> => {
  try {
    const response = await apiClient.post("/auth/signup", userData);
    return response.data; // Return whatever user data the backend returns after signup
  } catch (error) {
    console.error("Signup error:", error);
    throw error;
  }
};

// Log in an existing user
export const login = async (
  email: string,
  password: string
): Promise<User | null> => {
  try {
    const response = await apiClient.post("/auth/login", {
      email,
      password,
    });
    if (response.status === 200) {
      return response.data; // Return user data after successful login
    }
    return null; // Return null on failure
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

// Fetch the current user's information (after login)
export const getUserInfo = async (): Promise<User> => {
  try {
    const response = await apiClient.get("/users/me");
    return response.data; // Return the user data
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw error;
  }
};

// Update the current user's information
export const updateUserInfo = async (userInfo: User): Promise<User> => {
  try {
    const response = await apiClient.patch("/users/me", userInfo);
    return response.data; // Return updated user data
  } catch (error) {
    console.error("Update user info error:", error);
    throw error;
  }
};

// Interface for AdminPromptData
interface AdminPromptData {
  company_description: string;
  department_outline: string;
  acronyms: string;
}

// Fetch admin-specific prompts
export const getAdminPrompt = async (): Promise<AdminPromptData> => {
  try {
    const response = await apiClient.get("/company/1");
    return response.data; // Return the admin prompt
  } catch (error) {
    console.error("Admin prompt error:", error);
    throw error;
  }
};
// Fetch admin-specific prompts
export const getComponents = async (): Promise<ComponentType[]> => {
  try {
    const response = await apiClient.get("/company/1/component_type");
    return response.data; // Return the admin prompt
  } catch (error) {
    console.error("Admin promptcomponents error:", error);
    throw error;
  }
};

export const updateAdminPrompt = async (
  data: AdminPromptData
): Promise<void> => {
  try {
    await apiClient.patch("/company/1", data);
    console.log("Admin prompt updated successfully");
  } catch (error) {
    console.error("Error updating admin prompt:", error);
    console.log(data);
    throw error;
  }
};
export const addComponentType = async (
  component: ComponentType
): Promise<ComponentType> => {
  try {
    const response = await apiClient.post("/company/1/component_type", {
      name: component.name,
      prompt: component.prompt,
    });
    console.log("Component type added successfully.");
    return response.data; // Return the newly created component from the API
  } catch (error) {
    console.error("Error adding component type:", error);
    throw error;
  }
};

export const updateComponentType = async (
  component: ComponentType
): Promise<void> => {
  try {
    await apiClient.patch(`/company/1/component_type/${component.id}`, {
      id: component.id,
      name: component.name,
      prompt: component.prompt,
    });
    console.log("Component type added successfully.");
  } catch (error) {
    console.error("Error adding component type:", error);
    throw error;
  }
};
// ApiService.ts
export const deleteComponentType = async (id: string): Promise<void> => {
  try {
    await apiClient.delete(`/company/1/component_type/${id}`);
  } catch (error) {
    console.error(`Error deleting component type with id ${id}:`, error);
    throw error;
  }
};

// Interface for UserPromptData
interface UserPromptData {
  role: string;
  job_description: string;
  acronyms: string;
  response_style: string;
  response_length_words: number;
  context_sensitivity: number;
}

// Fetch user-specific prompt data
export const getUserPrompt = async (): Promise<UserPromptData> => {
  try {
    const response = await apiClient.get("/users/me");
    return response.data; // Return the user prompt data
  } catch (error) {
    console.error("User prompt error:", error);
    throw error;
  }
};

// Update the user-specific prompt data
export const updateUserPrompt = async (data: UserPromptData): Promise<void> => {
  try {
    await apiClient.patch("/users/me", data);
    console.log("User prompt updated successfully");
  } catch (error) {
    console.error("Error updating user prompt:", error);
    console.log(data);
    throw error;
  }
};

// Send a message to a specific chat and return the system response if available
export const sendMessage = async (
  chatId: number,
  prompt: string,
  documenttypes: string[]
): Promise<Message> => {
  try {
    const payload = {
      prompt,
      documenttypes,
    };
    const response = await apiClient.post(
      `/users/me/chats/${chatId}/messages`,
      payload
    );
    console.log(response.data);
    return response.data; // Assuming the response returns the full message object
  } catch (error) {
    console.error(`Error sending message to chat ${chatId}:`, error);
    throw error;
  }
};

// Fetch all conversations for the logged-in user
export const fetchConversations = async (): Promise<Conversation[]> => {
  try {
    const response = await apiClient.get("/users/me/chats");

    // Ensure we filter out any conversations that are marked as Archived
    const activeConversations = response.data
      ? response.data.filter(
          (conversation: Conversation) => !conversation.Archived
        )
      : [];

    return activeConversations;
  } catch (error) {
    console.error("Error fetching conversations:", error);
    throw error;
  }
};

// Fetch messages for a specific conversation
export const fetchMessages = async (chatId: number): Promise<Message[]> => {
  try {
    const response = await apiClient.get(`/users/me/chats/${chatId}/messages`);

    // Ensure we return an empty array if messages are null
    return response.data || [];
  } catch (error) {
    console.error(`Error fetching messages for chat ${chatId}:`, error);
    throw error;
  }
};
export const fetchMessageReferences = async (
  chatId: number,
  messageId: number
) => {
  try {
    const response = await apiClient.get(
      `/users/me/chats/${chatId}/messages/${messageId}`
    );
    return response.data[0].References; // Return the references data from the API response
  } catch (error) {
    console.error(
      `Error fetching references for chat ${chatId}, message ${messageId}:`,
      error
    );
    throw error;
  }
};
// Add a new conversation
export const addConversation = async (): Promise<Conversation> => {
  try {
    const response = await apiClient.post("/users/me/chats", {
      name: `New Conversation ${Date.now()}`, // Assuming the conversation has a name field
    });
    return response.data; // Return the created conversation
  } catch (error) {
    console.error("Error adding conversation:", error);
    throw error;
  }
};

// Update conversation
export const updateConversationName = async (
  conversationId: number,
  chatName: string
): Promise<void> => {
  try {
    await apiClient.patch(`/users/me/chats/${conversationId}`, {
      chat_name: chatName,
    });
  } catch (error) {
    console.error(`Error updating conversation ${conversationId}:`, error);
    throw error;
  }
};

// Delete a conversation
export const deleteConversation = async (
  conversationId: number
): Promise<void> => {
  try {
    await apiClient.delete(`/users/me/chats/${conversationId}`);
  } catch (error) {
    console.error("Error deleting conversation:", error);
    throw error;
  }
};
// This function will eventually handle the API call
export const SendImprovementReport = async (
  type: "bug" | "feature",
  message: string
) => {
  console.log(`Sending ${type} report with message: ${message}`);
  // Simulate a delay
  return new Promise((resolve) => setTimeout(resolve, 1000));
};
