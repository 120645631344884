import React, { useEffect, useState } from "react";
import { FiEye } from "react-icons/fi";
import Modal from "./Modal";
import ChatMessageMD from "./ChatMessageMD";

interface RightSidebarProps {
  currentConversationId: number | null;
}

const RightSidebar: React.FC<RightSidebarProps> = ({
  currentConversationId,
}) => {
  const [sectionData, setSectionData] = useState({
    D3: "",
    D4: "",
    D5: "",
    D7: "",
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");
  const [currentDx, setCurrentDx] = useState("");
  const [editableText, setEditableText] = useState("");

  // Load data from localStorage when the conversation changes
  useEffect(() => {
    if (currentConversationId) {
      const storedData = JSON.parse(localStorage.getItem("chatData") || "{}");
      const dataForCurrentChat = storedData[currentConversationId] || {
        D3: "",
        D4: "",
        D5: "",
        D7: "",
      };
      setSectionData(dataForCurrentChat);
    }
  }, [currentConversationId]);

  const openModal = (dx: string, description: string) => {
    setCurrentDx(dx);
    setCurrentDescription(description);
    setEditableText(description); // Initialize editable text with the description
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleSave = () => {
    if (currentConversationId) {
      const updatedSectionData = {
        ...sectionData,
        [currentDx.split(":")[0]]: editableText,
      };
      setSectionData(updatedSectionData);

      // Save to localStorage
      const storedData = JSON.parse(localStorage.getItem("chatData") || "{}");
      storedData[currentConversationId] = updatedSectionData;
      localStorage.setItem("chatData", JSON.stringify(storedData));

      closeModal();
    }
  };

  return (
    <div className="bg-white p-4 border-l border-gray-300 overflow-y-auto">
      <div className="space-y-4">
        {/* D3 Section */}
        <div>
          <div className="flex justify-between items-center">
            <div className="bg-gray-100 p-2 rounded-full mb-2">
              <span className="font-bold">D3</span>
              <span className="text-gray-800 ml-4">Containment actions</span>
            </div>
            <button
              onClick={() =>
                openModal("D3: Containment actions", sectionData.D3)
              }
              className="ml-auto text-gray-500 hover:text-gray-700"
              title="View D3"
            >
              <FiEye size={20} />
            </button>
          </div>
          <div className="max-h-50 overflow-y-auto p-3 border border-gray-300 rounded-lg bg-gray-50">
            <p className="text-sm text-gray-600">
              <ChatMessageMD message={sectionData.D3 || "No data"} />
            </p>
          </div>
        </div>

        {/* D4 Section */}
        <div>
          <div className="flex justify-between items-center">
            <div className="bg-gray-100 p-2 rounded-full mb-2">
              <span className="font-bold">D4</span>
              <span className="text-gray-800 ml-4">Root Cause</span>
            </div>
            <button
              onClick={() => openModal("D4: Root Cause", sectionData.D4)}
              className="ml-auto text-gray-500 hover:text-gray-700"
              title="View D4"
            >
              <FiEye size={20} />
            </button>
          </div>
          <div className="max-h-50 overflow-y-auto p-3 border border-gray-300 rounded-lg bg-gray-50">
            <p className="text-sm text-gray-600">
              <ChatMessageMD message={sectionData.D4 || "No data"} />
            </p>
          </div>
        </div>

        {/* D5 Section */}
        <div>
          <div className="flex justify-between items-center">
            <div className="bg-gray-100 p-2 rounded-full mb-2">
              <span className="font-bold">D5</span>
              <span className="text-gray-800 ml-4">Corrective actions</span>
            </div>
            <button
              onClick={() =>
                openModal("D5: Corrective actions", sectionData.D5)
              }
              className="ml-auto text-gray-500 hover:text-gray-700"
              title="View D5"
            >
              <FiEye size={20} />
            </button>
          </div>
          <div className="max-h-50 overflow-y-auto p-3 border border-gray-300 rounded-lg bg-gray-50">
            <p className="text-sm text-gray-600">
              <ChatMessageMD message={sectionData.D5 || "No data"} />
            </p>
          </div>
        </div>

        {/* D7 Section */}
        <div>
          <div className="flex justify-between items-center">
            <div className="bg-gray-100 p-2 rounded-full mb-2">
              <span className="font-bold">D7</span>
              <span className="text-gray-800 ml-4">Preventive actions</span>
            </div>
            <button
              onClick={() =>
                openModal("D7: Perspective actions", sectionData.D7)
              }
              className="ml-auto text-gray-500 hover:text-gray-700"
              title="View D7"
            >
              <FiEye size={20} />
            </button>
          </div>
          <div className="max-h-50 overflow-y-auto p-3 border border-gray-300 rounded-lg bg-gray-50">
            <p className="text-sm text-gray-600">
              <ChatMessageMD message={sectionData.D7 || "No data"} />
            </p>
          </div>
        </div>
      </div>

      {/* Modal for expanded view and editing */}
      <Modal isVisible={isModalVisible} onClose={closeModal} size="large">
        <h2 className="text-xl font-semibold mb-4">{currentDx}</h2>
        <textarea
          className="w-full h-80 p-2 border rounded-lg"
          value={editableText}
          onChange={(e) => setEditableText(e.target.value)}
          title="Edit the section content" // Add a title attribute
          placeholder="Enter the updated content here" // Add a placeholder attribute
        />
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={handleSave}
            className="bg-violet-700 text-white px-4 py-2 rounded-lg hover:bg-violet-800 transition"
          >
            Save
          </button>
          <button
            onClick={closeModal}
            className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default RightSidebar;
