import React, { ReactNode, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  children: ReactNode;
  size?: "small" | "large"; // Optional size control
}

const Modal: React.FC<ModalProps> = ({
  isVisible,
  onClose,
  children,
  size = "small",
}) => {
  // Close modal with escape key (handle visibility inside the hook)
  useEffect(() => {
    const handleEscKey = (e: KeyboardEvent) => {
      if (e.key === "Escape" && isVisible) {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isVisible, onClose]);

  // Return null if modal is not visible
  if (!isVisible) return null;

  // Handle modal size dynamically
  const modalSizeClass = size === "large" ? "max-w-4xl" : "max-w-md";

  // Handle backdrop click (clicking outside the modal)
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40 flex items-center justify-center"
        onClick={handleBackdropClick}
        role="dialog"
        aria-modal="true"
      >
        {/* Modal Content */}
        <div
          className={`relative bg-white p-6 rounded-lg shadow-lg w-full ${modalSizeClass} max-h-[90vh] overflow-y-auto`}
        >
          {/* Close Button */}
          <button
            onClick={onClose}
            title="Close"
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition"
          >
            <AiOutlineClose size={24} />
          </button>

          {/* Scrollable content */}
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

export default Modal;
