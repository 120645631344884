import React from "react";
import { FiPlus } from "react-icons/fi";
import ConversationItem from "./ConversationItem";
import Conversation from "models/Conversation";

interface SidebarProps {
  conversations: Conversation[];
  onAdd: () => void;
  onEdit: (id: number, newTitle: string) => void; // Update to include new title
  onDelete: (id: number) => void;
  onSelect: (id: number) => void;
  currentConversationId: number | null;
}

const Sidebar: React.FC<SidebarProps> = ({
  conversations,
  onAdd,
  onEdit,
  onDelete,
  onSelect,
  currentConversationId,
}) => {
  return (
    <div className=" bg-white p-4 overflow-y-auto">
      <button
        onClick={onAdd}
        className="flex items-center  w-full bg-gray-200 text-black px-2 py-2 rounded-lg hover:bg-violet-700 hover:text-white transition mb-4 pr-4 pl-4"
      >
        <span className="mr-2 flex-1 justify-start text-start">
          Add Conversation
        </span>
        <FiPlus className="text-white bg-violet-700 justify-end  rounded-full w-4 h-4" />
      </button>

      <h2 className="text-xl font-semibold mb-4">Conversations</h2>

      <ul>
        {conversations.map((conversation) => (
          <ConversationItem
            key={conversation.ID}
            id={conversation.ID}
            text={conversation.ChatName} // Display the conversation name
            onEdit={() => onEdit(conversation.ID, conversation.ChatName)}
            onDelete={() => onDelete(conversation.ID)}
            onSelect={() => onSelect(conversation.ID)}
            onSaveEdit={(newTitle) => onEdit(conversation.ID, newTitle)} // Pass the new title to onEdit
            isSelected={conversation.ID === currentConversationId}
          />
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
