import React, { useState, useRef, useEffect } from "react";
import { FiSend } from "react-icons/fi";
import ChatBubble from "../components/ChatBubble";
import Message from "models/Message";
import ReferenceModal from "./ReferenceModal";
import ChatMessageMD from "./ChatMessageMD";

interface ChatSectionProps {
  messages: Message[];
  onSendMessage: (message: string) => void;
  sections: Array<"D3" | "D4" | "D5" | "D7">;
  handleCopyToSection: (
    messageText: string,
    section: "D3" | "D4" | "D5" | "D7"
  ) => void;
  creationDate: string | null;
}

const ChatSection: React.FC<ChatSectionProps> = ({
  messages,
  onSendMessage,
  sections,
  handleCopyToSection,
  creationDate,
}) => {
  const [userInput, setUserInput] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentChatId, setCurrentChatId] = useState<number | null>(null);
  const [currentMessageId, setCurrentMessageId] = useState<number | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null); // Ref for scrolling to the bottom

  const handleSendMessage = () => {
    if (userInput.trim() === "") return;
    onSendMessage(userInput);
    setUserInput("");
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height after sending
    }
  };

  const handleOpenReferences = (chatId: number, messageId: number) => {
    setCurrentChatId(chatId);
    setCurrentMessageId(messageId);
    setIsModalVisible(true);
  };

  // Automatically adjust the height of the textarea as the user types
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to auto
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set it based on the content
    }
  };

  useEffect(() => {
    adjustTextareaHeight(); // Adjust height when the input changes
  }, [userInput]);

  // Scroll to the bottom whenever messages change
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom when messages are updated
  }, [messages]);

  return (
    <div className="flex-1 flex flex-col bg-gray-100 overflow-y-auto">
      {creationDate && (
        <div className="p-4 bg-white text-gray-600 border-b">
          <p className="text-sm italic">
            Conversation started on{" "}
            {new Date(creationDate).toLocaleDateString()}
          </p>
        </div>
      )}
      {/* Scrollable chat section */}
      <div className="flex-1 overflow-y-auto p-6">
        {messages.map((message, index) => (
          <div key={index} className="mb-4">
            {/* Display user message */}
            {message.Question && (
              <ChatBubble key={index} message={message.Question} />
            )}
            {/* Display system response */}
            {message.Answer && (
              <div className="flex items-start mt-2">
                <div className="bg-violet-700 text-white rounded-full h-5 w-5 flex items-center justify-center mr-3 p-2">
                  Q
                </div>
                <div className="ai-message">
                  {/* Use ChatMessage to render the AI's answer in Markdown */}
                  <ChatMessageMD message={message.Answer} />
                </div>
              </div>
            )}
            {/* Show copy buttons if there's an answer */}
            {message.Answer && (
              <div className="flex justify-between mt-2">
                <div className="flex space-x-2 bg-white rounded-full">
                  {sections.map((section, idx) => (
                    <button
                      key={idx}
                      onClick={() =>
                        handleCopyToSection(message.Answer || "", section)
                      }
                      className="text-violet-700 hover:bg-violet-700 hover:text-white rounded-full px-3 py-1 transition"
                    >
                      {`Copy to ${section}`}
                    </button>
                  ))}
                </div>
                <button
                  className="bg-black text-white px-4 py-2 rounded-full hover:bg-gray-700 transition"
                  onClick={() =>
                    handleOpenReferences(message.ChatID, message.ID)
                  }
                >
                  For reference &nbsp;&gt;
                </button>
              </div>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} /> {/* Reference to scroll to bottom */}
      </div>

      {/* Input section */}
      {/* Input Section */}
      <div className="p-5">
        {/* Rounded div container for input and button */}
        <div className="relative flex items-center bg-white p-2 rounded-xl shadow-md w-full">
          {/* Expanding textarea inside the div */}
          <textarea
            ref={textareaRef}
            className="w-full p-1 pr-14 pl-3 text-violet-700 text-justify bg-transparent rounded-xl border-none focus:outline-none resize-none overflow-y-auto max-h-40 leading-relaxed"
            placeholder="Type your message..."
            value={userInput}
            onChange={(e) => {
              setUserInput(e.target.value);
              autoResizeTextarea();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevent new line on Enter
                handleSendMessage();
              }
            }}
            rows={1}
          />
          {/* Send button */}
          <button
            onClick={handleSendMessage}
            title="Send"
            className="absolute right-8 bottom-1 bg-violet-700 text-white p-2 rounded-full hover:bg-violet-900 transition"
          >
            <FiSend size={24} />
          </button>
        </div>
      </div>

      {/* Reference modal */}
      {currentChatId && currentMessageId && (
        <ReferenceModal
          chatId={currentChatId}
          messageId={currentMessageId}
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </div>
  );
};

export default ChatSection;
