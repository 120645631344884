import React, { useState, useEffect } from "react";
import { getUserInfo, updateUserInfo } from "../services/ApiService"; // Assuming API functions for fetching/updating user info

interface UserSettingsProps {
  onClose: () => void;
}

const UserSettings: React.FC<UserSettingsProps> = ({ onClose }) => {
  const [email, setEmail] = useState<string>(""); // Email is not editable
  const [username, setUsername] = useState<string>(""); // Editable username
  const [password, setPassword] = useState<string>(""); // Password input
  const [confirmPassword, setConfirmPassword] = useState<string>(""); // Confirm password input
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserInfo();
        setEmail(userData.email); // Email is not editable
        setUsername(userData.username); // Pre-fill the username
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      }
    };

    fetchUserData();
  }, []);

  // Handle saving user information
  const handleSave = async () => {
    if (password && password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    const updatedData = {
      username,
      email, // Email is not editable, so we keep the same
      password: password || "", // Only send password if provided
    };

    try {
      await updateUserInfo(updatedData);
      setSaveSuccess(true);
      setTimeout(() => {
        setSaveSuccess(false);
        onClose(); // Close the modal after success
      }, 3000);
    } catch (error) {
      console.error("Failed to update user info:", error);
      setError("Failed to save changes");
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg max-w-3xl mx-auto">
      {/* Success Message */}
      {saveSuccess && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green-500 text-white px-4 py-2 rounded shadow-lg z-50">
          Changes saved successfully!
        </div>
      )}

      {/* Error Message */}
      {error && (
        <div className="bg-red-500 text-white px-4 py-2 rounded mb-4">
          {error}
        </div>
      )}

      {/* Email (Non-editable) */}
      <div>
        <label className="block text-gray-700">Email</label>
        <p className="w-full p-2 bg-gray-100 rounded-lg border border-gray-300">
          {email}
        </p>
      </div>

      {/* Username */}
      <div className="mt-4">
        <label className="block text-gray-700">Username</label>
        <input
          type="text"
          className="w-full p-2 border border-gray-300 rounded-lg"
          placeholder="Your Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>

      {/* Password */}
      <div className="mt-4">
        <label className="block text-gray-700">Password</label>
        <input
          type="password"
          className="w-full p-2 border border-gray-300 rounded-lg"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      {/* Confirm Password */}
      <div className="mt-4">
        <label className="block text-gray-700">Confirm Password</label>
        <input
          type="password"
          className="w-full p-2 border border-gray-300 rounded-lg"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>

      {/* Save Button */}
      <button
        className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
};

export default UserSettings;
