import React, { useState, useEffect } from "react";
import { FiUser, FiSettings, FiArrowLeft, FiFileText } from "react-icons/fi";
import { AiOutlineBug } from "react-icons/ai"; // Importing bug icon from Ant Design Icons

import Modal from "./Modal";
import AdminPrompt from "./AdminPrompt";
import UserPrompt from "./UserPrompt";
import UserSettings from "./UserSettings";
import ReportModal from "./ReportModal"; // Import ReportModal
import { getUserInfo } from "services/ApiService";
import User from "models/User";

interface SettingsModalProps {
  isVisible: boolean;
  onClose: () => void;
  userInfo: {
    username: string;
    email: string;
    name: string;
    password: string;
  };
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
  saveSuccess: boolean;
  user: User | null;
  logout: () => void;
  onComponentUpdate: () => void;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
  isVisible,
  onClose,
  userInfo,
  onInputChange,
  onSave,
  saveSuccess,
  user,
  logout,
  onComponentUpdate,
}) => {
  const [currentView, setCurrentView] = useState<
    "menu" | "userInfo" | "adminPrompt" | "userPrompt"
  >("menu");
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [reportType, setReportType] = useState<"bug" | "feature">("bug");

  // Fetch user info and check if user is admin
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userInfo = await getUserInfo();
        setIsAdmin(userInfo.role === "admin");
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      }
    };

    if (isVisible) {
      setCurrentView("menu");
      fetchUserInfo();
    }
  }, [isVisible]);

  // Function to open the report modal
  const openReportModal = (type: "bug" | "feature") => {
    setReportType(type);
    setIsReportModalVisible(true);
  };

  // Close report modal
  const closeReportModal = () => {
    setIsReportModalVisible(false);
  };

  // Render menu view

  const renderMenu = () => (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold mb-4">Settings Menu</h2>

      <button
        onClick={() => setCurrentView("userInfo")}
        className="w-full bg-gray-200 text-violet-700 p-2 rounded-lg hover:bg-violet-700 hover:text-white transition flex items-center"
      >
        <FiUser className="mr-2" /> {/* User icon */}
        User Information Settings
      </button>

      {isAdmin && (
        <button
          onClick={() => setCurrentView("adminPrompt")}
          className="w-full bg-gray-200 text-violet-700 p-2 rounded-lg hover:bg-violet-700 hover:text-white transition flex items-center"
        >
          <FiSettings className="mr-2" /> {/* Settings icon */}
          Admin Prompt
        </button>
      )}

      <button
        onClick={() => setCurrentView("userPrompt")}
        className="w-full bg-gray-200 text-violet-700 p-2 rounded-lg hover:bg-violet-700 hover:text-white transition flex items-center"
      >
        <FiSettings className="mr-2" /> {/* User prompt icon */}
        User Prompt
      </button>

      <button
        onClick={() => openReportModal("bug")}
        className="w-full bg-gray-200 text-violet-700 p-2 rounded-lg hover:bg-violet-700 hover:text-white transition flex items-center border-violet-700 border-2"
      >
        <AiOutlineBug className="mr-2" /> {/* Bug report icon */}
        Report Bug
      </button>

      <button
        onClick={() => openReportModal("feature")}
        className="w-full bg-gray-200 text-violet-700 p-2 rounded-lg hover:bg-violet-700 hover:text-white transition flex items-center border-violet-700 border-2"
      >
        <FiFileText className="mr-2" /> {/* Feature request icon */}
        Feature Request
      </button>

      <button
        onClick={() => {
          onClose();
          logout();
        }}
        className="w-full bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition flex items-center"
      >
        <FiArrowLeft className="mr-2" /> {/* Logout icon */}
        Logout
      </button>
    </div>
  );

  const renderUserInfo = () => (
    <div className="flex flex-col h-full">
      <div className="flex-shrink-0 mb-4 absolute">
        <button
          onClick={() => setCurrentView("menu")}
          className="flex items-center text-violet-700 hover:text-violet-800 transition"
        >
          <FiArrowLeft className="mr-2" /> Back
        </button>
      </div>
      <div className="flex-1 overflow-y-auto">
        <UserSettings onClose={onClose} />
      </div>
    </div>
  );

  const renderAdminPrompt = () => (
    <div className="flex flex-col h-full">
      <div className="flex-shrink-0 mb-4 absolute">
        <button
          onClick={() => setCurrentView("menu")}
          className="flex items-center text-violet-700 hover:text-violet-800 transition"
        >
          <FiArrowLeft className="mr-2" /> Back
        </button>
      </div>
      <div className="flex-1 overflow-y-auto">
        <AdminPrompt onClose={onClose} onComponentUpdate={onComponentUpdate} />
      </div>
    </div>
  );

  const renderUserPrompt = () => (
    <div className="flex flex-col h-full">
      <div className="flex-shrink-0 mb-4 absolute">
        <button
          onClick={() => setCurrentView("menu")}
          className="flex items-center text-violet-700 hover:text-violet-800 transition"
        >
          <FiArrowLeft className="mr-2" /> Back
        </button>
      </div>
      <div className="flex-1 overflow-y-auto">
        <UserPrompt onClose={onClose} />
      </div>
    </div>
  );

  const modalSize =
    currentView === "adminPrompt" || currentView === "userPrompt"
      ? "large"
      : "small";

  return (
    <>
      <Modal isVisible={isVisible} onClose={onClose} size={modalSize}>
        {currentView === "menu" && renderMenu()}
        {currentView === "userInfo" && renderUserInfo()}
        {currentView === "adminPrompt" && renderAdminPrompt()}
        {currentView === "userPrompt" && renderUserPrompt()}
      </Modal>

      {/* Report Modal */}
      {isReportModalVisible && (
        <ReportModal
          reportType={reportType}
          isVisible={isReportModalVisible}
          onClose={closeReportModal}
        />
      )}
    </>
  );
};

export default SettingsModal;
