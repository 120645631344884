import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import {
  loginUser,
  registerUser,
  logoutUser,
  getStoredUser,
} from "services/AuthService";
import User from "models/User";

// Define the types for the AuthContext
interface AuthContextType {
  isAuthenticated: boolean;
  loading: boolean;
  user: User | null; // Store user object instead of token
  login: (username: string, password: string) => Promise<boolean>;
  register: (userData: User) => Promise<boolean>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Hook to use the AuthContext
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null); // Store the user object in state

  useEffect(() => {
    const storedUser = getStoredUser();
    if (storedUser) {
      setUser(storedUser);
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const login = async (
    username: string,
    password: string
  ): Promise<boolean> => {
    try {
      const loggedInUser = await loginUser(username, password);
      if (loggedInUser) {
        setUser(loggedInUser); // Set the user data
        localStorage.setItem("user", JSON.stringify(loggedInUser)); // Store the user object in localStorage
        setIsAuthenticated(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Login error:", error);
      return false;
    }
  };

  const register = async (userData: User): Promise<boolean> => {
    try {
      const registeredUser = await registerUser(userData);
      if (registeredUser) {
        setUser(registeredUser); // Set the user data
        localStorage.setItem("user", JSON.stringify(registeredUser)); // Store the user object in localStorage
        setIsAuthenticated(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Registration error:", error);
      return false;
    }
  };

  const logout = () => {
    logoutUser(); // Call logout from AuthService
    setUser(null); // Clear the user from state
    localStorage.removeItem("user"); // Remove user from localStorage
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, loading, user, login, register, logout }} // Pass the user instead of token
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};
