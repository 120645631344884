import { login, signup } from "services/ApiService";
import User from "../models/User";

// Update `loginUser` to return `User | null` instead of `true | { token: string; }`

export const loginUser = async (
  username: string,
  password: string
): Promise<User | null> => {
  // Return User or null
  try {
    const user = await login(username, password); // Assuming `login` returns the User object

    if (user) {
      // You should not return `true`, but return the actual user object
      return user;
    } else {
      return null; // Return null on failure
    }
  } catch (error) {
    console.error("Login error:", error);
    return null; // Return null on error
  }
};

export const registerUser = async (userData: User): Promise<User | null> => {
  try {
    const user = await signup(userData);

    if (user) {
      return user; // Return the registered user object
    } else {
      return null; // Return null on failure
    }
  } catch (error) {
    console.error("Registration error:", error);
    return null;
  }
};

export const getStoredUser = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

export const logoutUser = async () => {
  try {
    // Notify the backend to invalidate the session
    //await apiClient.post("/auth/logout", {}, { withCredentials: true }); // Logout endpoint

    // Remove the stored user data from localStorage
    localStorage.removeItem("user");

    console.log("Logout successful");
  } catch (error) {
    console.error("Logout error:", error);
    throw error;
  }
};
