import React, { useState, useEffect } from "react";
import {
  getAdminPrompt,
  getUserPrompt,
  updateUserPrompt,
} from "../services/ApiService"; // Import the API functions

const UserPrompt: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [adminAcronyms, setAdminAcronyms] = useState<
    { key: string; value: string }[]
  >([]);
  const [acronyms, setAcronyms] = useState<{ key: string; value: string }[]>(
    []
  );
  const [acronymName, setAcronymName] = useState<string>("");
  const [acronymValue, setAcronymValue] = useState<string>("");
  const [jobDescription, setJobDescription] = useState("");
  const [responseLength, setResponseLength] = useState<number>(400); // Default: Medium (400 words)
  const [responseStyle, setResponseStyle] = useState<string>("Formal"); // Default: Formal
  const [contextSensitivity, setContextSensitivity] = useState<number>(5); // Default: Mid-level (5)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const setResponseLengthWithSave = (value: number) => {
    setResponseLength(value);
    handleSaveWithResponseLength(value);
  };

  const setResponseStyleWithSave = (value: string) => {
    setResponseStyle(value);
    handleSaveWithResponseStyle(value);
  };
  const setContextSensitivityWithSave = (value: number) => {
    setContextSensitivity(value);
    handleSaveWithContextSensitivity(value);
  };

  // Fetch admin acronyms and user data when the component mounts
  useEffect(() => {
    const fetchAdminAcronyms = async () => {
      try {
        const data = await getAdminPrompt();
        const acronymsParsed = data.acronyms
          ? data.acronyms
              .split(";")
              .map((acronym) => {
                const [key, value] = acronym.split(":");
                return key && value
                  ? { key: key.trim(), value: value.trim() }
                  : null;
              })
              .filter((item) => item !== null) // Remove any null entries
          : [];
        setAdminAcronyms(acronymsParsed as { key: string; value: string }[]);
      } catch (error) {
        console.error("Failed to fetch admin acronyms:", error);
      }
    };

    const fetchUserPrompt = async () => {
      try {
        const data = await getUserPrompt(); // Get user-specific prompt data

        // Set state with fetched user data
        setJobDescription(data.job_description || "");
        setResponseLength(data.response_length_words || 400); // Default to 400 if not provided
        setResponseStyle(data.response_style || "Formal"); // Convert style to lowercase for consistency
        setContextSensitivity(data.context_sensitivity || 5); // Default to 5 if not provided

        const acronymsParsed = data.acronyms
          ? data.acronyms.split(";").map((acronym) => {
              const [key, value] = acronym.split(":");
              return { key, value };
            })
          : [];
        setAcronyms(acronymsParsed); // Populate user acronyms
      } catch (error) {
        console.error("Failed to fetch user prompt:", error);
      }
    };

    fetchAdminAcronyms();
    fetchUserPrompt();
  }, []);

  // Handle adding user acronyms
  const handleAddAcronym = () => {
    if (acronymName && acronymValue) {
      setAcronyms((prevAcronyms) => {
        const updatedAcronyms = [
          ...prevAcronyms,
          { key: acronymName, value: acronymValue },
        ];
        handleSaveWithAcronyms(updatedAcronyms);
        return updatedAcronyms;
      });
      setAcronymName("");
      setAcronymValue("");
    }
  };

  // Handle deleting user acronyms
  const handleDeleteAcronym = (index: number) => {
    setAcronyms(acronyms.filter((_, i) => i !== index));
  };

  // Save the user prompt data
  const handleSave = async () => {
    try {
      const acronymsParsed = acronyms
        .map((acronym) => `${acronym.key}:${acronym.value}`)
        .join(";");
      const data = {
        role: "admin", // Assuming fixed role for now
        job_description: jobDescription,
        acronyms: acronymsParsed,
        response_length_words: responseLength,
        response_style: responseStyle,
        context_sensitivity: contextSensitivity,
      };

      // Call the API to update user settings
      await updateUserPrompt(data);

      // Show success message and close modal after 3 seconds
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        // dtb: do not close onClose(); // Close the modal after success
      }, 3000);
    } catch (error) {
      console.error("Failed to save user prompt:", error);
    }
  };
  const handleSaveWithAcronyms = async (
    updatedAcronyms: { key: string; value: string }[]
  ) => {
    try {
      const acronymsParsed = updatedAcronyms
        .map((acronym) => `${acronym.key}:${acronym.value}`)
        .join(";");
      const data = {
        role: "admin",
        job_description: jobDescription,
        acronyms: acronymsParsed,
        response_length_words: responseLength,
        response_style: responseStyle,
        context_sensitivity: contextSensitivity,
      };

      await updateUserPrompt(data);
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 2000);
    } catch (error) {
      console.error("Failed to save user prompt:", error);
    }
  };
  const handleSaveWithResponseLength = async (updatedLength: number) => {
    try {
      const acronymsParsed = acronyms
        .map((acronym) => `${acronym.key}:${acronym.value}`)
        .join(";");
      const data = {
        role: "admin",
        job_description: jobDescription,
        acronyms: acronymsParsed,
        response_length_words: updatedLength,
        response_style: responseStyle,
        context_sensitivity: contextSensitivity,
      };

      await updateUserPrompt(data);
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 2000);
    } catch (error) {
      console.error("Failed to save response length:", error);
    }
  };

  const handleSaveWithResponseStyle = async (updatedStyle: string) => {
    try {
      const acronymsParsed = acronyms
        .map((acronym) => `${acronym.key}:${acronym.value}`)
        .join(";");
      const data = {
        role: "admin",
        job_description: jobDescription,
        acronyms: acronymsParsed,
        response_length_words: responseLength,
        response_style: updatedStyle,
        context_sensitivity: contextSensitivity,
      };

      await updateUserPrompt(data);
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 2000);
    } catch (error) {
      console.error("Failed to save response style:", error);
    }
  };
  const handleSaveWithContextSensitivity = async (
    updatedSensitivity: number
  ) => {
    try {
      const acronymsParsed = acronyms
        .map((acronym) => `${acronym.key}:${acronym.value}`)
        .join(";");
      const data = {
        role: "admin",
        job_description: jobDescription,
        acronyms: acronymsParsed,
        response_length_words: responseLength,
        response_style: responseStyle,
        context_sensitivity: updatedSensitivity,
      };

      await updateUserPrompt(data);
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 2000);
    } catch (error) {
      console.error("Failed to save context sensitivity:", error);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg max-w-3xl mx-auto">
      {/* Success Message */}
      {showSuccessMessage && (
        <div className="fixed bottom-4 right-4 bg-violet-700 text-white px-4 py-2 rounded shadow-lg z-50">
          Changes saved successfully!
        </div>
      )}

      {/* Job Description */}
      <h3 className="font-bold">Please describe what your job is</h3>
      <textarea
        className="w-full border rounded-lg p-2 mt-2"
        rows={4}
        placeholder="Describe your job..."
        value={jobDescription}
        onChange={(e) => setJobDescription(e.target.value)}
        onBlur={handleSave}
      ></textarea>

      {/* Admin Acronyms */}
      <div className="mt-4">
        <h3 className="font-bold">
          These are the acronyms that your admin added:
        </h3>
        <div className="flex flex-wrap mt-2">
          {adminAcronyms.map((acronym, index) => (
            <div
              key={index}
              className="bg-gray-300 text-gray-800 px-2 py-1 rounded-full mr-2 mb-2"
            >
              {acronym.key}: {acronym.value}
            </div>
          ))}
        </div>
      </div>

      {/* User Acronyms */}
      <div className="mt-4">
        <h3 className="font-bold">You can add more acronyms here:</h3>
        <div className="flex space-x-2 mt-2">
          <input
            type="text"
            className="border rounded-lg p-2 flex-1"
            placeholder="Acronym name"
            value={acronymName}
            onChange={(e) => setAcronymName(e.target.value)}
          />
          <span className="flex items-center text-violet-700 text-2xl">→</span>
          <input
            type="text"
            className="border rounded-lg p-2 flex-1"
            placeholder="Acronym"
            value={acronymValue}
            onChange={(e) => setAcronymValue(e.target.value)}
          />
          <button
            type="button"
            onClick={handleAddAcronym}
            className="bg-violet-700 text-white px-4 py-2 rounded-lg hover:bg-violet-800"
          >
            +
          </button>
        </div>
        <div className="mt-4 flex flex-wrap">
          {acronyms.map((acronym, index) => (
            <div
              key={index}
              className="bg-gray-200 px-2 py-1 rounded-full mr-2 mb-2"
            >
              {acronym.key}: {acronym.value}
              <button
                onClick={() => handleDeleteAcronym(index)}
                className="ml-2 text-red-500 hover:text-red-700"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Response Length */}
      <div className="mt-4">
        <h3 className="font-bold">These are some parameters for the LLM:</h3>
        <div className="flex space-x-4 mt-2 ">
          <label>
            <input
              type="radio"
              value={50}
              checked={responseLength === 50}
              onChange={() => setResponseLengthWithSave(50)}
            />{" "}
            Short (50 words)
          </label>
          <label>
            <input
              type="radio"
              value={100}
              checked={responseLength === 100}
              onChange={() => setResponseLengthWithSave(100)}
            />{" "}
            Medium (100 words)
          </label>
          <label>
            <input
              type="radio"
              value={200}
              checked={responseLength === 200}
              onChange={() => setResponseLengthWithSave(200)}
            />{" "}
            Long (200 words)
          </label>
        </div>
      </div>

      {/* Response Style */}
      <div className="mt-4">
        <h3 className="font-bold">Response style:</h3>
        <div className="flex space-x-4 mt-2">
          <label>
            <input
              type="radio"
              value="formal"
              checked={responseStyle === "Formal"}
              onChange={() => setResponseStyleWithSave("Formal")}
            />{" "}
            Formal
          </label>
          <label>
            <input
              type="radio"
              value="technical"
              checked={responseStyle === "Technical"}
              onChange={() => setResponseStyleWithSave("Technical")}
            />{" "}
            Technical
          </label>
          <label>
            <input
              type="radio"
              value="simplified"
              checked={responseStyle === "Simplified"}
              onChange={() => setResponseStyleWithSave("Simplified")}
            />{" "}
            Simplified
          </label>
          <label>
            <input
              type="radio"
              value="narrative"
              checked={responseStyle === "Narrative"}
              onChange={() => setResponseStyleWithSave("Narrative")}
            />{" "}
            Narrative
          </label>
        </div>
      </div>

      {/* Context Sensitivity */}
      <div className="mt-4">
        <h3 className="font-bold">Context sensitive:</h3>
        <label htmlFor="contextSensitivity" className="sr-only">
          Context Sensitivity Slider
        </label>
        <input
          id="contextSensitivity"
          type="range"
          min={0}
          max={5}
          value={contextSensitivity}
          onChange={(e) =>
            setContextSensitivityWithSave(parseInt(e.target.value))
          }
          onBlur={handleSave}
          className="w-full mt-2"
          title="Adjust context sensitivity"
        />
        <p className="text-gray-500 text-sm">
          Adjust how much context the LLM retains from previous interactions (0:
          Low, 5: High).
        </p>
      </div>

      {/* Save button */}
      <button
        className="mt-6 bg-violet-700 text-white px-4 py-2 rounded-lg hover:bg-violet-800"
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
};

export default UserPrompt;
